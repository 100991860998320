import React from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import dracula from "prism-react-renderer/themes/dracula"

const MDXComponents = {
  h1: props => <h1 className='text-xl mt-6 font-semibold sm:text-2xl text-slate-900' {...props} />,
  h2: props => <h2 className='text-xl mt-6 font-semibold sm:text-2xl text-slate-900' {...props} />,
  h3: props => <h3 className='text-lg mt-3 font-semibold sm:text-xl text-slate-900' {...props} />,
  h4: props => <h4 className='text-lg mt-3 font-semibold sm:text-xl text-slate-900' {...props} />,
  h5: props => <h5 className='text-lg mt-3 font-semibold sm:text-xl text-slate-900' {...props} />,
  h6: props => <h6 className='text-lg mt-3 font-semibold sm:text-xl text-slate-900' {...props} />,

  a: props => <a
    className='leading-relaxed text-sm sm:text-base lg:text-lg text-sky-700 hover:underline'
    {...props}
  />,

  p: props => <p
    className='leading-relaxed text-sm sm:text-base lg:text-lg mb-4 text-slate-700'
    {...props}
  />,

  ul: props => <ul
    className='leading-relaxed text-sm sm:text-base lg:text-lg mb-4 ml-8 list-disc text-slate-700'
    {...props}
  />,

  ol: props => <ol
    className='leading-relaxed text-sm sm:text-base lg:text-lg mb-4 ml-8 list-decimal text-slate-700'
    {...props}
  />,

  td: props => <td
    className='leading-relaxed text-sm sm:text-base lg:text-lg px-2 py-1 border border-slate-400'
    {...props}
  />,

  th: props => <th
    className='leading-relaxed text-sm sm:text-base lg:text-lg px-2 py-1 border border-slate-400'
    {...props}
  />,

  table: props => <table
    className='leading-relaxed text-sm sm:text-base lg:text-lg mb-6'
    {...props}
  />,

  inlineCode: props => <code
    className='
      leading-relaxed rounded-sm shadow-sm
      text-xs sm:text-sm lg:text-base py-0.5 px-1 bg-slate-200 text-slate-700
    '
    {...props}
  />,

  code: ({ children, className, metastring }) => {
    // Pull the className
    const language = className?.replace(/language-/, '') ?? ""
    const matches = metastring?.match(/title:"(.*?)"|title:'(.*?)'/)
    const title = matches?.[1] ?? matches?.[2]

    return (
      <Highlight {...defaultProps}
        code={children.trim()}
        language={language}
        theme={dracula}
      >
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <div className='flex flex-col items-start drop-shadow-lg mb-4'>
            {
              title && (
                <div
                  className='
                    rounded-t-lg
                    bg-slate-600 text-slate-200 text-sm sm:text-base
                    py-1 px-2 sm:px-4 md:px-6 m-0
                  '
                >
                  {title}
                </div>
              )
            }
            <div
              className={`
                ${className}
                flex flex-row
                w-full overflow-x-auto
                rounded-lg ${title && 'rounded-tl-none'}
                text-sm sm:text-base
                p-2 sm:p-4 md:p-6 m-0
              `}
              style={{ ...style }}
            >
              <pre>
                <div className='flex flex-row gap-1.5 sm:gap-3'>
                  <div className='flex flex-col items-end'>
                    {tokens.map((_, index) => {
                      return (
                        <div className='opacity-30'>{index + 1}</div>
                      )
                    })}
                  </div>
                  <div className='flex flex-col items-start'>
                    {tokens.map((line, index) => {
                      const lineProps = getLineProps({ line, key: index })
                      return (
                        <div key={index} {...lineProps}>
                          {line.map((token, key) => (
                            <span key={key}{...getTokenProps({ token, key })} />
                          ))}
                        </div>
                      )
                    })}
                  </div>
                </div>
              </pre>
            </div>
          </div>
        )}
      </Highlight>
    )
  },
};

export default MDXComponents;
